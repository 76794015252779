<template>
  <div class="app-container">
    <el-row class="margin-bottom-20" >
      <el-col :lg="12" :md="12" :sm="16" :xs="16" :offset="0">
        <el-input prefix-icon="el-icon-search" size="mini" placeholder="Buscar" v-model="searchForm.query" v-on:change="handleSearch()" clearable></el-input>
      </el-col>
        <el-button
          class="margin-left-20"
          @click.stop.prevent="handleSearch"
          type="primary"
          icon="el-icon-search"
          size="mini"
        >Buscar</el-button>
        <el-button
        class="margin-left-20"
          @click.stop.prevent="handleClean"
          type="warning"
          icon="el-icon-delete"
          size="mini"
        >Limpiar Filtros</el-button>
        <el-button
        class="margin-left-20"
          @click.stop.prevent="exportExcelFile"
          type="success"
          icon="el-icon-bottom"
          size="mini"
        >Exportar Excel</el-button>
        <el-button
        class="margin-left-20"
          @click.stop.prevent="handleCreated"
          type="success"
          icon="el-icon-circle-plus-outline"
          size="mini"
        >Nuevo</el-button>
    </el-row>
     <el-row >
      <el-col :span="5" class=" margin-left-10">
        <div class=" margin-left-10">
          <span class=" margin-filter">Filtro</span> <br>
          <el-select
          class=" margin-left-10"
            size="mini"
            v-model="searchForm.status"
            filterable
            clearable
            placeholder="Ninguno"
            v-on:change="handleSearch()">
              <el-option
                v-for="itemPublication in publishOptions"
                :key="itemPublication.value"
                :label="itemPublication.label"
                :value="itemPublication.value">
              </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="5" class=" margin-left-10">
        <div class=" margin-left-10">
          <span class=" margin-filter">Fecha inicial</span> <br>
          <el-date-picker
          class=" margin-left-10"
            @change="handleSearch()"
            clearable
            size="mini"
            v-model="searchForm.initDate"
            type="date"
            format="dd-MM-yyyy"
            value-format="yyyy-MM-dd"
            placeholder="Mayor a ">
          </el-date-picker>
        </div>
      </el-col>
      <el-col :span="5" class=" margin-left-10">
        <div class=" margin-left-10">
          <span class=" margin-filter">Fecha final</span> <br>
          <el-date-picker
          class=" margin-left-10"
            @change="handleSearch()"
            clearable
            size="mini"
            v-model="searchForm.endDate"
            type="date"
            format="dd-MM-yyyy"
            value-format="yyyy-MM-dd"
            placeholder="Menor a">
          </el-date-picker>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="end">
        <el-pagination small @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
          :total="parseInt(totalPages)">
        </el-pagination>
    </el-row>
    <el-table
      v-loading="loading"
      element-loading-text="Cargando"
      :data="list"
      fit
      highlight-current-row
      style="width: 100%"
      size="mini"
    >
      <!-- <el-table-column label="Correo" sortable  prop="email" fixed width="220">
      </el-table-column> -->
      <el-table-column label="Imagen" align="center">
        <template slot-scope="scope">
          <el-image :src="scope.row.image" class="size-image">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
        </el-image>
        </template>
      </el-table-column>
      <el-table-column label="Título" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Orden" align="center">
        <template slot-scope="scope" >
          <span>{{ scope.row.order }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Publicado" align="center">
        <div slot-scope="scope" v-if="scope.row.status=== true">
            Publicado
        </div>
        <div v-else>
             No publicado
        </div>
      </el-table-column>
      <el-table-column label="Fecha Alta" align="center">
        <template slot-scope="scope" >
          <span>{{ scope.row.updated_at | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Opciones" width="230" align="center">
          <template slot-scope="scope" >
            <el-button
              @click.stop.prevent="handleEdit(scope.$index, scope.row)"
              size="mini"
              type="primary"
              round
            >Editar <i class="el-icon-arrow-right el-icon-circle-plus-outline"></i></el-button>
            <el-button
              @click.stop.prevent="handleDestroy(scope.$index, scope.row)"
              size="mini"
              type="danger"
              round
            >Eliminar <i class="el-icon-arrow-right el-icon-remove-outline"></i></el-button>
            <!-- <span>{{ scope.row.deviceLoggersLast }}</span> -->
          </template>
        </el-table-column>
    </el-table>
    <el-row type="flex" justify="end">
        <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
          :total="parseInt(totalPages)">
        </el-pagination>
    </el-row>
  </div>
</template>
<script>
import { search, getPage, exportExcel, destroy } from '@/api/courses'

export default {
  data () {
    return {
      searchForm: {
        query: '',
        initDate: '',
        endDate: '',
        status: ''
      },
      publishOptions: [{
        value: 'true',
        label: 'Publicado'
      }, {
        value: 'false',
        label: 'No publicado'
      }],
      totalPages: '',
      totalItemPage: '',
      currentPage: '',
      list: null,
      loading: false
    }
  },
  mounted () {
    this.handleSearch()
  },
  methods: {
    async handleSearch () {
      this.loading = true
      await search(this.searchForm)
        .then(response => {
          console.log(response.data)
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPage(page, this.showItempageValue)
        .then(response => {
          this.list = response.data.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      await getPage(this.currentPage, val)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleCreated () {
      this.$router.push({ name: 'courses.create' })
    },
    handleEdit (index, row) {
      console.log(row._id)
      this.$router.push({ name: 'courses.edit', params: { _id: row._id } })
    },
    handleDestroy (index, row) {
      this.loadingDelete = true
      this.$confirm('Se borrará permanentemente. Continuar?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(async () => {
          await destroy(row._id)
            .then(response => {
              this.$message({
                showClose: true,
                message: 'Se eliminó correctamente!',
                type: 'success'
              })
              this.list.splice(index, 1)
            })
            .catch(this.responseCatch)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Delete canceled'
          })
        })
        .finally(() => (this.loadingDelete = false))
    },
    async exportExcelFile () {
      this.loading = true
      await exportExcel(this.searchForm)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a')
          link.href = url
          const name = 'Reporte_de_cursos_' + new Date() + '.xlsx'
          link.setAttribute('download', name)
          document.body.appendChild(link)
          link.click()
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleClean () {
      this.searchForm.query = ''
      this.searchForm.initDate = ''
      this.searchForm.endDate = ''
      this.searchForm.status = ''
      this.handleSearch()
    }
  }
}
</script>
